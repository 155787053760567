import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import useCitiesQuery from "hooks/use-cities-query";
import classnames from "classnames";
import { SubmitButton } from "components/button";
import posed from "react-pose";
import { tween } from "popmotion";
import styles from "./styles.module.css";

const ShakePose = posed.div({
  shake: {
    applyAtEnd: { x: 0 },
    applyAtStart: { x: -10 },
    x: 0,
    transition: {
      type: "spring",
      stiffness: 1000,
      damping: 10,
      duration: 4
    }
  }
});

const FlashPose = posed.div({
  flash: {
    applyAtEnd: { opacity: 1 },
    opacity: 0.1,
    transition: ({ from, to }) => tween({ from, to, yoyo: 5, duration: 150 })
  }
});

const ContactForm = ({ onSubmit }) => {
  const allCities = useCitiesQuery();

  console.log(allCities);

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: ""
      }}
      validate={values => {
        let errors = {};

        Object.keys(values).forEach(key => {
          if (!values[key]) {
            errors[key] = "Required";
          }
        });

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = "Invalid email address";
        }
        return errors;
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status, isValid, errors }) => (
        <Form
          className={classnames({
            [styles.contactForm]: true,
            [styles.contactFormSubmitting]: isSubmitting,
            [styles.contactFormSubmitted]: status === "submitted"
          })}
        >
          <label>
            NAME
            <Field type="text" name="name" />
            <FlashPose pose={isSubmitting && errors.name ? "flash" : ""}>
              <ErrorMessage
                className={styles.error}
                name="name"
                component="div"
              />
            </FlashPose>
          </label>
          <label>
            EMAIL
            <Field type="email" name="email" />
            <FlashPose pose={isSubmitting && errors.email ? "flash" : ""}>
              <ErrorMessage
                className={styles.error}
                name="email"
                component="div"
              />
            </FlashPose>
          </label>

          <label className={styles.message}>
            MESSAGE
            <Field component="textarea" name="message" />
            <FlashPose pose={isSubmitting && errors.message ? "flash" : ""}>
              <ErrorMessage
                className={styles.error}
                name="message"
                component="div"
              />
            </FlashPose>
          </label>

          <ShakePose pose={isSubmitting && !isValid ? "shake" : ""}>
            <SubmitButton
              type="submit"
              disabled={isSubmitting || status === "submitted"}
              submitting={isSubmitting}
            >
              {status === "submitted" ? "SUBMITTED" : "SUBMIT"}
            </SubmitButton>
          </ShakePose>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
