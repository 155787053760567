import React from "react";

const Button = ({ children, ...otherProps }) => {
  return <button {...otherProps}>{children}</button>;
};

export default Button;
export { default as TextButton } from "./text";
export { default as SecondaryButton } from "./secondary";
export { default as PrimaryButton } from "./primary";
export { default as MenuButton } from "./menu";
export { default as DropdownButton } from "./dropdown";
export { default as SubmitButton } from "./submit";
