import React from "react";
import classnames from "classnames";
import Button from "../";
import styles from "./styles.module.css";

const MenuButton = ({ isOpen = false, ...otherProps }) => (
  <Button className={styles.menuButton} {...otherProps}>
    <div
      className={classnames({ [styles.burger]: true, [styles.isOpen]: isOpen })}
    >
      <span className={styles.burgerItem}></span>
      <span className={styles.burgerItem}></span>
      <span className={styles.burgerItem}></span>
    </div>
  </Button>
);

export default MenuButton;
