class RequestError extends Error {
  constructor(message, errorObject) {
    super(message);

    this.name = "RequestError";
    this.errorObject = errorObject;
    this.date = new Date();
  }
}

const parse = response =>
  new Promise((resolve, reject) => {
    response
      .json()
      .then(data => {
        resolve({ response, data });
      })
      .catch(e => {
        reject(new RequestError("Could not parse response as JSON.", e));
      });
  });

const checkStatus = ({ response, data = {} }) => {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(data);
  }
  return Promise.reject(data);
};

const request = (url, { method = "get", headers, body }) =>
  new Promise((resolve, reject) => {
    fetch(url, { method, headers, body })
      .then(parse)
      .then(checkStatus)
      .then(data => {
        resolve(data);
      })
      .catch(e => {
        if (e.errors) {
          reject(new RequestError(e.errors[0].detail, e));
        } else {
          reject(e);
        }
      });
  });

export const post = (url, data) =>
  request(url, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json"
    }
  });

export default request;
