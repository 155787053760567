import React from "react";
import Button from "../";
import classnames from "classnames";
import styles from "./styles.module.css";

const TextButton = ({ children, className, ...otherProps }) => (
  <Button
    className={classnames({
      [styles.textButton]: true,
      [className]: className
    })}
    {...otherProps}
  >
    {children}
  </Button>
);

export default TextButton;
