import { useStaticQuery, graphql } from "gatsby";

export const useCitiesQuery = () => {
  const data = useStaticQuery(
    graphql`
      query AllCitiesQuery {
        allMarkdownRemark(
          filter: { fields: { slug: { regex: "/(cities)/" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              html
              frontmatter {
                title
                ticketshop
              }
            }
          }
        }
      }
    `
  );

  return data.allMarkdownRemark.edges.map(({ node }) => ({
    html: node.html,
    slug: node.fields.slug,
    data: node.frontmatter
  }));
};

export default useCitiesQuery;
