import React from "react";
import classnames from "classnames";
import PrimaryButton from "../primary";
import Loader from "components/loader";
import styles from "./styles.module.css";

const SubmitButton = ({ children, submitting = false, ...otherProps }) => (
  <PrimaryButton className={styles.submitButton} {...otherProps}>
    <span
      className={classnames({
        [styles.children]: true,
        [styles.submitting]: submitting
      })}
    >
      {children}
    </span>
    {submitting && <Loader className={styles.loader} />}
  </PrimaryButton>
);

export default SubmitButton;
