import React from "react";
import { Location } from "@reach/router";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import useSiteSettings from "hooks/use-site-settings";

function SEO({ description, lang, meta, image, title = "" }) {
  const settings = useSiteSettings();

  const metaDescription = description || settings.seo.siteDescription;
  const metaTitle = title
    ? `${title} | ${settings.seo.siteTitle}`
    : settings.seo.siteTitle;
  const ogImage = image || settings.seo.ogImage;

  return (
    <Location>
      {({ location }) => (
        <Helmet
          htmlAttributes={{
            lang
          }}
          title={metaTitle}
          meta={[
            {
              name: `description`,
              content: metaDescription
            },
            {
              property: `og:url`,
              content: `${settings.seo.siteUrl}${location.pathname || "/"}`
            },
            {
              property: `og:title`,
              content: title
            },
            {
              property: `og:description`,
              content: metaDescription
            },
            ,
            {
              property: `og:image`,
              content: `${settings.seo.siteUrl}${ogImage}`
            },
            {
              property: `og:type`,
              content: `website`
            },
            {
              name: `twitter:card`,
              content: `summary`
            },
            {
              name: `twitter:title`,
              content: title
            },
            {
              name: `twitter:description`,
              content: metaDescription
            }
          ].concat(meta)}
        />
      )}
    </Location>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object)
};

export default SEO;
