import React from "react";
import classnames from "classnames";
import style from "./styles.module.css";

const Circle = () => (
  <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <circle transform="translate(1 1)" cx="8" cy="8" r="8" />
  </svg>
);

export default ({ className }) => (
  <div
    className={classnames({ [style.loader]: true, [className]: true })}
    role="progressbar"
  >
    <Circle />
    <Circle />
    <Circle />
  </div>
);
