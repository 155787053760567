import React from "react";
import classnames from "classnames";
import Button from "../";
import styles from "./styles.module.css";

const PrimaryButton = ({ children, className, ...otherProps }) => (
  <Button
    className={classnames({
      [styles.primaryButton]: true,
      [className]: className
    })}
    {...otherProps}
  >
    {children}
  </Button>
);

export default PrimaryButton;
