import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "components/layout";
import ContactForm from "components/forms/contact";
import useSiteSettings from "hooks/use-site-settings";
import useGoogleSheet from "hooks/use-google-sheet";
import styles from "./styles.module.css";

export const query = graphql`
  query ContactPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`;

const ContactTemplate = ({ data }) => {
  const settings = useSiteSettings();
  const sheet = useGoogleSheet(settings.data.googleSpreadsheetId);
  const [error, setError] = useState(null);

  const { html } = data.markdownRemark;
  const { title } = data.markdownRemark.frontmatter;

  return (
    <Layout title={title}>
      <div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <ContactForm
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            console.log(values);
            try {
              await sheet.insertRow("Contact Form", [
                [values.name, values.email, values.message]
              ]);
              setStatus("submitted");
            } catch (e) {
              setError(e.message);
            } finally {
              setSubmitting(false);
            }
          }}
        />

        {error && <div className={styles.error}>{error}</div>}
      </div>
    </Layout>
  );
};

export default ContactTemplate;
