import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import posed, { PoseGroup } from "react-pose";
import { TextButton } from "components/button";
import styles from "./styles.module.css";

const Indicator = ({ className, color = "#fe9b23" }) => (
  <div className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="7"
      viewBox="0 0 14 7"
    >
      <path fill={color} fillRule="evenodd" d="M14.071 0L7 7.071-.071 0z" />
    </svg>
  </div>
);

const PosedDiv = posed.div({
  enter: { opacity: 1, y: "0" },
  exit: {
    opacity: 0,
    y: "-50px",
    transition: {
      opacity: {
        duration: 100
      }
    }
  }
});

const DropdownButton = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = e => {
    if (
      isExpanded &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isExpanded]);

  return (
    <>
      <TextButton
        className={styles.button}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div>{title}</div>
        <Indicator
          className={classnames({
            [styles.indicator]: true,
            [styles.indicatorExpanded]: isExpanded
          })}
        ></Indicator>
      </TextButton>
      <PoseGroup>
        {isExpanded && (
          <PosedDiv key="whatevs">
            <div ref={dropdownRef}>{children}</div>
          </PosedDiv>
        )}
      </PoseGroup>
    </>
  );
};

export default DropdownButton;
