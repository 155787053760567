import { post } from "utils/request";

export const useGoogleSheet = id => {
  const apiUrl = process.env.GOOGLE_SHEET_API_URL;

  const insertRow = async (sheetName, rows) => {
    try {
      const result = await post(apiUrl, { spreadsheetId: id, sheetName, rows });
      return result.data;
    } catch (e) {
      throw e;
    }
  };

  return { id, insertRow };
};

export default useGoogleSheet;
