import { useStaticQuery, graphql } from "gatsby";

export const useSiteSettings = () => {
  const data = useStaticQuery(
    graphql`
      query SettingsQuery {
        seo: settingsYaml(settingsSlug: { eq: "seo" }) {
          siteTitle
          siteUrl
          siteDescription
          ogImage
        }
        analytics: settingsYaml(settingsSlug: { eq: "analytics" }) {
          googleTrackingId
          googleTagManagerId
          facebookTrackingId
        }
        social: settingsYaml(settingsSlug: { eq: "social" }) {
          networks {
            name
            url
            icon
          }
        }
        header: settingsYaml(settingsSlug: { eq: "header" }) {
          logo
        }
        data: settingsYaml(settingsSlug: { eq: "data" }) {
          googleSpreadsheetId
        }
      }
    `
  );

  return data;
};

export default useSiteSettings;
