import React from "react";
import classnames from "classnames";
import ContentWrapper from "components/content-wrapper";
import Header from "components/header";
import Footer from "components/footer";
import SiteWrapper from "components/site-wrapper";
import SEO from "components/seo";
import styles from "./styles.module.css";

export default ({ title = "", className = null, children, ...rest }) => (
  <div
    className={classnames({
      [styles.layout]: true,
      [className]: className
    })}
    {...rest}
  >
    <SEO title={title} />
    <SiteWrapper>{/* <Header /> */}</SiteWrapper>
    <ContentWrapper>{children}</ContentWrapper>
    <SiteWrapper>{/* <Footer /> */}</SiteWrapper>
  </div>
);
